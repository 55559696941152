<template>
  <ReviewsView
    v-if="reviews && reviews.length > 0"
    :reviews
    :subtitle
    :title
    :tracking
  />
</template>

<script lang="ts" setup>
import type { ReviewsProps } from '@backmarket/http-api/src/api-specs-content/models/reviews'
import { getReviews } from '@backmarket/http-api/src/api-specs-reviews/reviews'
import { useHttpFetch } from '@backmarket/nuxt-module-http/useHttpFetch'

import type { ContentBlockProps } from '../../models/content-block'

import { mapToReviews } from './Reviews.utils'
import ReviewsView from './ReviewsView.vue'

const props = defineProps<ReviewsProps & ContentBlockProps>()

const { data: reviews } = await useHttpFetch(getReviews, {
  queryParams: { public_id__in: props.reviews_ids },
  transform: (res) => mapToReviews(res.results),
})
</script>
