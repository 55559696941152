import type { Review as ApiReview } from '@backmarket/http-api/src/api-specs-reviews/types/review'

import type { Review } from './ReviewsView.vue'

/**
 *
 * @param apiReviews reviews returned from the API
 * @returns only reviews with pictures attached, and mapped for ReviewsView component
 */
export function mapToReviews(apiReviews: ApiReview[]): Review[] {
  return apiReviews
    .filter((review) => {
      const hasPictures = !!review.details?.find(
        (detail) => !!detail.attachments && detail.attachments?.length > 0,
      )

      return hasPictures
    })
    .map((review) => {
      const attachmentsDetail = review.details?.find(
        (detail) => !!detail.attachments && detail.attachments?.length > 0,
      )

      const reviewPictures =
        attachmentsDetail?.attachments?.map((attachment) => attachment.url) ||
        []

      return {
        ...review,
        reviewDate: review.createdAt,
        purchaseDate: review.product.purchasedAt,
        pictures: reviewPictures,
      }
    })
}
